import { gql } from '@apollo/client'

const GET_RECOMMENDED_REVIEWS_WITHOUT_DATE = gql`
  query GET_RECOMMENDED_REVIEWS_WITHOUT_DATE(
    $count: Int!
    $type: ReviewType
    $orderBy: OrderByType
    $genre: String
    $contentType: ReviewContentType
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    reviews(
      limit: $count
      type: $type
      recommended: true
      orderBy: $orderBy
      genre: $genre
      contentType: $contentType
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      id
      title
      imageUrl
      contentUrl
      blurb
      labels {
        id
        name
        contentUrl
        live
      }
      author {
        id
        name
        imageUrl
        username
        contributor
      }
    }
  }
`

export default GET_RECOMMENDED_REVIEWS_WITHOUT_DATE
