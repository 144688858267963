import { gql } from '@apollo/client'

const REVIEW_FIELDS_NO_DATE = gql`
  fragment reviewFields on Review {
    id
    title
    imageUrl
    contentUrl
    blurb
    recommended
    labels {
      id
      name
      contentUrl
      live
    }
    author {
      id
      name
      imageUrl
      username
      contributor
    }
  }
`

const GET_REVIEWS_WITHOUT_DATE = gql`
  query GET_REVIEWS_WITHOUT_DATE(
    $count: Int!
    $type: ReviewType
    $recommended: Boolean
    $orderBy: OrderByType
    $genre: String
    $contentType: ReviewContentType
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    reviews(
      limit: $count
      type: $type
      recommended: $recommended
      orderBy: $orderBy
      genre: $genre
      contentType: $contentType
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      ...reviewFields
    }
  }
  ${REVIEW_FIELDS_NO_DATE}
`

export { REVIEW_FIELDS_NO_DATE }
export default GET_REVIEWS_WITHOUT_DATE
